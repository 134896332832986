<template>
  <div>
    <van-nav-bar
      title="选择作业类型"
      left-text="返回"
      left-arrow
      @click-left="Bank"
    />
    <div class="farmwork-box">
      <div class="farmwork-list" v-for="(item, index) in list" :key="index">
        <div class="farmwork-list-cnt" @click="detail(item.id)" :class="{active: item.id ===  iscolor}">
          <img :src="item.icon" alt="" class="fornwork"/>
          {{ item.name }}
        </div>
      </div>
    </div>
    <div style="width:90%; margin: 10px auto">
      <van-button round block type="info" native-type="submit" style="background:#001f90" @click="seve()"
        >确定</van-button
      >
    </div>
  </div>
</template>

<script>
import { NavBar, Button } from "vant";
import {
  getfarmworkTypeList,
  postfarmworkTypeSeve
} from "@/service/farmworkType";
export default {
  name: "farmworkType",
  data() {
    return {
      iscolor: 0,
      list: [
        {
          id: 1,
          name: "整地",
          icon: require("@/assets/homeImg/icon/Landpreparation.svg")
        },
        {
          id: 2,
          name: "播种",
          icon: require("@/assets/homeImg/icon/sowing.svg")
        },
        {
          id: 3,
          name: "施肥",
          icon: require("@/assets/homeImg/icon/Fertilize.svg")
        },
        {
          id: 4,
          name: "打药",
          icon: require("@/assets/homeImg/icon/Fightmedicine.svg")
        },
        {
          id: 5,
          name: "修剪",
          icon: require("@/assets/homeImg/icon/prune.svg")
        },
        {
          id: 6,
          name: "授粉",
          icon: require("@/assets/homeImg/icon/pollination.svg")
        },
        {
          id: 7,
          name: "疏花",
          icon: require("@/assets/homeImg/icon/Thinning.svg")
        },
        {
          id: 8,
          name: "套袋",
          icon: require("@/assets/homeImg/icon/Bagging.svg")
        },
        {
          id: 9,
          name: "收割",
          icon: require("@/assets/homeImg/icon/Harvest.svg")
        },
        {
          id: 10,
          name: "出售",
          icon: require("@/assets/homeImg/icon/sell.svg")
        }
      ],
      typeId: ""
    };
  },
  components: {
    VanNavBar: NavBar,
    VanButton: Button
  },
  mounted() {
    this.farmworkTypeList();
  },
  methods: {
    Bank() {
      this.$router.go(-1);
    },
    farmworkTypeList() {
      getfarmworkTypeList().then(res => {
        // const data = res.data
        // this.list = data
      });
    },
    detail(id) {
      this.typeId = id;
      this.iscolor = id
    },
    seve() {
      const landIds = this.$store.state.landIds;
      const cropIds = this.$store.state.cropIds;
      const typeId = this.typeId;
      const workDate = this.$store.state.workDate;
      const cultivationCondition = this.$store.state.da_crops_id;
      postfarmworkTypeSeve(landIds, cropIds, typeId, workDate, cultivationCondition).then(res => {
        if (res.code === 200) {
          this.$router.push({
            path: "/"
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.farmwork-box {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .farmwork-list {
    width: 20%;
    text-align: center;
    margin-bottom: 30px;
    .fornwork {
      width: 50px;
      height: 18px;
    }
  }
}
.active{
  border: 1px solid #1989fa;
  color: #1989fa
}
</style>
